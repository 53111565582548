
































































































































































































































import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { StakeBoosters } from "@/constants";
import { FixedNumber } from "@ethersproject/bignumber";

@Observer
@Component({
  components: {
    SelectMonster: () => import("./select-monster.vue"),
    APRDetail: () => import("./APRDetail.vue"),
    APRDetailTable: () => import("./APRDetailTable.vue"),
    SelectLockPeriod: () => import("./select-lock-period.vue"),
    ConnectButton: () => import("@/components/ConnectButton.vue"),
    CharacterAvatar: () =>
      import("@/components/character/character-avatar.vue"),
  },
})
export default class FirstStakeCard extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;
  walletStore = walletStore;

  boosters = StakeBoosters;
  showMore = true;
  form = false;

  estimatedAPR(multiplier) {
    return this.vm.userApy.mulUnsafe(FixedNumber.from(multiplier + ""));
  }

  toogleShowMore() {
    this.showMore = !this.showMore;
  }

  getDMLG() {
    window.open(
      `https://pancakeswap.finance/swap?outputCurrency=${process.env.VUE_APP_TOKEN_ADDRESS}`,
      "_blank"
    );
  }
}
